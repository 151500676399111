@import "../../../../styles/global";


.security-section {
  width: 100%;
  background: url("../../../../assets/landingPage/engageUsers/background-img.png") no-repeat center,
  linear-gradient(180deg, rgb(70, 25, 98) 0%, rgb(33, 25, 98) 100%);
  background-size: 100vw 100vh, 100vh;
  background-blend-mode: overlay;
  display: flex;
  justify-content: center;
  align-items: center;

  .security-content {
    display: flex;
    margin: 20vh 0;

    .security-image {
      display: flex;
      justify-content: end;
      width: 50%;

      img {
        width: 80%;
      }
    }

    .section-heading {
      display: flex;
      justify-content: center;

      h1 {
        font-family: $Montserrat-Black;
        font-style: italic;
        color: #ffffff;
      }
    }

  }

}
@media screen and (min-width: $large-screen) {
  .section-heading{
    h1{
      font-size: 4vw;
      width: 65%;

    }
  }
}


@media screen and (max-width: $small-screen) {
  .security-section {
    .security-content {
      flex-direction: column-reverse;
      align-items: center;
      margin: 10vh 0;


      .security-image {
        width: 100%;
        justify-content: center;

        img {
          width: 50%;
        }
      }

    }

    .section-heading {
      text-align: center;
      width: 100%;

      h1 {
        font-size: 5vw;
        width: 70%;
      }
    }
  }
}