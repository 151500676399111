@import "../../styles/global";


.label{
  //background-color: #EAEAEA;
  color: #000000;
  border: 1px solid #EAEAEA;
  border-radius: 25px;
  cursor: pointer;
  p{
   font-family: $Montserrat-SemiBold;
    font-size: 1vw;
    margin: 0;
    padding: 1.2vh 1.5vw;
  }
  &:active{
    background-color: #E8E5FF;
    border: 1px solid $blue;
    color: $blue;
  }
}