@import "../../../styles/global";

.menu-item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: .5vh 0vw 0.5vh 0;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  left: 20px;
  margin-bottom: 4vh;
  font-family: $Montserrat-Medium;

  &.active {
    width: max-content;
    background-color: #ffffff;
    color: $blue;
    border-radius: 5px;

    .icon {
      color: $blue;
    }
  }
  &:first-child{
    background-color: $light-green;
    width: max-content;
    border-radius: 5px;
    color: #000000;
    &.active {
      width: max-content;
      background-color: $light-green;
      color: #000000;
      border-radius: 5px;

      .icon {
        color:#000000 ;
      }
    }
  }

  .icon {
    padding: 0 .5vw;
  }

  .title-wrapper {
    flex: 1;
    width: 0;
    transition: all 0.5s;

    &.expanded {
      font-size: 18px;
      width: auto;
    }

    .title {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0 7px 0 10px;
    }
  }
}
