@import "../../../../styles/global";

@media screen and (min-width: $large-screen) {
  .ladderSectionCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    .ladderSectionCard-image {

    }

    .ladderSectionCard-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-family: $Montserrat-ExtraBold;
        font-size: 1.5vw;
        color: $heading-color;
      }

      p {
        font-family: $Montserrat-Medium;
        font-size: 1vw;
        color: #414F5F;
        width: 65%;
      }
    }
  }
}

@media screen and (max-width: $small-screen) {
  .ladderSectionCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;

    .ladderSectionCard-image {
max-width: 15vw;
      img{
        width: 15vw;
      }
    }

    .ladderSectionCard-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      h1 {
        font-family: $Montserrat-ExtraBold;
        font-size: 4vw;
        color: $heading-color;
        margin-bottom: 0;
      }

      p {
        font-family: $Montserrat-Medium;
        font-size: 3vw;
        color: #414F5F;
        width: 65%;
      }
    }
  }
}
