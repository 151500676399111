@import "../../../../styles/global";

@media screen and (min-width:$large-screen) {
  .heroSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background:
            url("../../../../assets/landingPage/engageUsers/background-img.png") no-repeat center top 15vh,
            linear-gradient(180deg, rgba(36,12,242,0.9) 0%, rgba(57,19,96,9) 100%);
    background-size:  100vw 82vh,100vh;
    //mix-blend-mode: overlay;
    background-blend-mode: overlay;

    .heroContent {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h1 {
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 6vw;
        margin-bottom: 0;
      }

      p {
        font-family: $Montserrat-Medium;
        font-size: 1.5vw;
        width: 70%;
      }
    }

    .logo-div {
      width: 80%;
      height: 80vh;
      background-color: #0C063C;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10vh;
      margin-bottom: 10vh;

      img {
        width: 5vw;
      }
    }
  }

}



@media screen and (max-width:$small-screen) {
  .heroSection {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    background:
            url("../../../../assets/landingPage/engageUsers/background-img.png") no-repeat center top 13vh,
            linear-gradient(180deg, rgba(36,12,242,0.9) 0%, rgba(57,19,96,1) 100%);
    background-size:  100vw 35vh,100vh;
    background-blend-mode: overlay;

    .heroContent {
      color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h1 {
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 7vw;
        margin-bottom: 0;
      }

      p {
        font-family: $Montserrat-Medium;
        font-size: 3vw;
        width: 93%;
      }
    }

    .logo-div {
      width: 80%;
      height: 40vh;
      background-color: #0C063C;
      border-radius: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5vh;
      margin-bottom: 10vh;

      img {
        width: 10vw;
      }
    }
  }

}