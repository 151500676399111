@import "../../../../styles/global";


.campaignsPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 5vh;

  .heading-wrapper {
    display: flex;
    flex-direction: column;
    width: 96%;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
    row-gap: 5vh;
    .campaignsPage-heading {


      h1 {
        font-family: $Montserrat-Black;
        font-size: 3vw;
        margin-bottom: 0;
      }
    }

    .campaignsPage-label {
      width: 93%;
      justify-content: start;
      display: flex;
      column-gap: 2vw;
      margin-bottom: 5vh;
    }
  }

  .campaignsPageCard-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-evenly;
    place-items: center;
    gap: 2vh 2vw;
    //margin: 0 5vh 5vw 5vh;
  }
}