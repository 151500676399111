@import "../../../../styles/global";

.reachYourNorth-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #318F95 0%, #309565 100%);
  text-align: center;

  h1 {
    font-size: 4vw;
    font-family: $Montserrat-Black;
    font-style: italic;
    width: 80%;
    color: #ffffff;
  }

  img {
    width: 30%;
    margin-bottom: 5vh;
  }
}

@media screen and (max-width: $small-screen) {
  .reachYourNorth-section {
    h1 {
      font-size:5vw;
    }
    img{
     width: 40%;
    }
    }
}