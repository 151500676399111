@import "../../../../styles/global";


@media screen and (min-width: $large-screen) {

  .harnessThePower-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #240CF2;

    .harness-heading {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;

      h1 {
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 4vw;
        color: #ffffff;
        width: 80%;
        margin-bottom: 0;
      }
    }

    .harness-image {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: -3vh;

      img {
        position: relative;
        bottom: 3vh;
      }
    }
  }
}


@media screen and (max-width: $small-screen) {

  .harnessThePower-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #240CF2;

    .harness-heading {
      text-align: center;
      width: 100%;
      display: flex;
      justify-content: center;

      h1 {
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 4vw;
        color: #ffffff;
        width: 80%;
        margin-bottom: 0;
      }
    }

    .harness-image {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: -1vh;

      img {
        position: relative;
        bottom: 1vh;
        width: 80%;
      }
    }
  }
}