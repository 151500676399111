@import "../../../../styles/global";

  .uniqueUsers{
    display: flex;
    width: 100%;
    background-color:#211962;
    justify-content: space-around;
    align-items: center;
    padding: 10vh 0;
    section{
      display: flex;
      flex-direction: column;
      text-align: center;
      color: #ffffff;
      h1{
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 5vw;
        margin-bottom: 0;
      }
      p{
        font-family: $Montserrat-ExtraBold;
        font-size: 1.5vw;
        margin-top: 0;
      }
    }
  }


@media  screen and (max-width:$small-screen) {
 .uniqueUsers{
   flex-wrap: wrap;
 }
}

