@import "../../styles/global";


.addCampaigns{
  width: 100%;
  height: 100%;
  background-color: $campaignsHeading;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    svg{
      color: #ffffff;
      font-size: 4vw;
    }
  }
}



.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 3;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #ffffff;
  padding: 1.5vh 2vw;
  border-radius: 3px;
  min-width: 30vw;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 2vh;
  h2{
   font-family: $Montserrat-Black;
    font-size: 1.8vw;

  }
  h3{
    font-family: $Montserrat-Medium;
    font-size: 1.2vw;

  }
  label{
    font-family: $Montserrat-Medium;
    font-size: 1.5vw;

  }
  input {
    font-family: $Montserrat-Medium;
    width: 100%;
    font-size: 1.5vw;
    border-radius: 5px;
    border: 1px solid #E4E6EB;
    padding: .5vw;
    outline: none;

    &:focus {
      border: .5px solid #AA99EC;
      outline: .5px solid #AA99EC;
    }
  }
  .modal-content-label{
    display: flex;
    width: 100%;
    column-gap: 1vw;
    margin-bottom: 2vh;
  }
  .close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    svg{
      color: #000000;
      font-size:2vw
    }
  }
}

