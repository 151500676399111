
.dashboard-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  min-height: 100svh;
  overflow: hidden;
  .dashboard-layout-card {
    width: 100%;
    height: 100vh;
    flex: 1;
    overflow: auto;
  }
}