@import "../../../../styles/global";


.cardTest{
  display: grid;
  grid-template-columns: repeat(5,auto);
  justify-content: space-between;
  place-items: center;
  gap: 2vh 2vw;
  margin: 0 5vh 5vw 5vh;
  .cardTest-heading{
    grid-column: 1/6;
    text-align: start;
    width: 100%;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 2;
    h1{
      font-size: 3vw;
      font-family: $Montserrat-Black;
    }

  }
}

@media screen and (max-width:$small-screen) {
  .cardTest{
    grid-template-columns: repeat(1,1fr);
    .cardTest-heading {
      grid-column: 1;

    }
  }
}