@import "../../../../styles/global";

$p-color: #333;


@media screen and (min-width: $large-screen) {

  .boostSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .customizeGame-left {
      width: 50%;

      h1 {
        background: $purple-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.3vw;
        letter-spacing: 2px;
        font-family: $Montserrat-Black;
      }

      p {
        color: $p-color;
        font-size: 2vw;
        font-family: $Montserrat-Black;
        width: 90%;

      }
    }

    .customizeGame-right {
      display: flex;
      width: 50%;
      background: $purple-gradient;
      align-items: center;
      justify-content: center;
      padding: 5vh 0;
      column-gap: 2vw;
      border-radius: 35px;
      img{
        width: 25%;
      }
      .customizeGame-img2{
        width: 30%;
      }
    }

    .webGameCampaign-left {
      width: 50%;

      h1 {
        background: $green-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.3vw;
        letter-spacing: 2px;
        font-family: $Montserrat-Black;
      }

      p {
        color: $p-color;
        font-size: 2vw;
        font-family: $Montserrat-Black;
        width: 90%;

      }

    }

    .webGameCampaign-right {
      width: 50%;
      display: flex;
      justify-content: center;
      padding: 5vh 0 0 0;
      column-gap: 2vw;
      border-radius: 35px;
      background: $green-gradient;

      img {
        align-self: flex-end;
      }

      .webGameCampaign-img3 {
        display: none;
      }

    }

    .superchargeGrowth-left {
      width: 50%;

      h1 {
        background: $orange-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.3vw;
        letter-spacing: 2px;
        font-family: $Montserrat-Black;
      }

      p {
        color: $p-color;
        font-size: 2vw;
        font-family: $Montserrat-Black;
        width: 90%;
      }

    }

    .superchargeGrowth-right {
      width: 50%;
      display: flex;
      background: $orange-gradient;
      //align-items: center;
      justify-content: end;
      padding: 5vh 0 0 0;
      column-gap: 2vw;
      border-radius: 35px;

      img {
        border-radius: 35px;
      }

      .superchargeGrowth-img2, .superchargeGrowth-img3 {
        display: none;
      }

    }
  }
}


@media screen and (max-width: $small-screen) {

  .boostSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .customizeGame-left {
      width: 100%;

      h1 {
        background: $purple-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3vw;
        letter-spacing: 2px;
        font-family: $Montserrat-Black;
      }

      p {
        color: $p-color;
        font-size: 4vw;
        font-family: $Montserrat-Black;
      }
    }

    .customizeGame-right {
      display: flex;
      width: 100%;
      background: $purple-gradient;
      align-items: center;
      justify-content: center;
      padding: 5vh 0;
      column-gap: 2vw;
      border-radius: 35px;
      img{
        width: 30%;
      }
      .customizeGame-img2{
        width: 34%;
      }
    }

    .webGameCampaign-left {
      width: 100%;

      h1 {
        background: $green-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3vw;
        letter-spacing: 2px;
        font-family: $Montserrat-Black;
      }

      p {
        color: $p-color;
        font-size: 4vw;
        font-family: $Montserrat-Black;
      }

    }

    .webGameCampaign-right {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 5vh 0 0 0;
      column-gap: 2vw;
      border-radius: 35px;
      background: $green-gradient;

      img {
        align-self: flex-end;
          width: 30%;

      }

      .webGameCampaign-img3 {
        display: none;
      }

    }

    .superchargeGrowth-left {
      width: 100%;

      h1 {
        background: $orange-gradient;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 3vw;
        letter-spacing: 2px;
        font-family: $Montserrat-Black;
      }

      p {
        color: $p-color;
        font-size: 4vw;
        font-family: $Montserrat-Black;
      }

    }

    .superchargeGrowth-right {
      width: 100%;
      display: flex;
      background: $orange-gradient;
      //align-items: center;
      justify-content: end;
      padding: 5vh 0 0 0;
      column-gap: 2vw;
      border-radius: 35px;

      img {
        border-radius: 25px;
        width: 90%;

      }

      .superchargeGrowth-img2, .superchargeGrowth-img3 {
        display: none;
      }

    }
  }
}