@import "../../../../styles/global";

@media screen and (min-width:$large-screen) {

  .boostYourBrand {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 10vh;
    margin-bottom: 10vh;
    .boost-heading {
      font-family: $Montserrat-Black;
      font-style: italic;
      font-size: 4vw;
      text-align: center;
      width: 70%;
margin-top: 10vh;
      color: $heading-color;
    }
  }
}




@media screen and (max-width:$small-screen) {

  .boostYourBrand {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 5vh;
    margin-bottom: 5vh;
    .boost-heading {
      font-family: $Montserrat-Black;
      font-style: italic;
      font-size: 6vw;
      text-align: center;
      width: 90%;
      margin-top: 5vh;
      color: $heading-color;
    }
  }
}