//FONTS :
@font-face {
  font-family: Montserrat-ExtraBold;
  src: local(Montserrat-ExtraBold), url("../font/Montserrat/static/Montserrat-ExtraBold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat-Medium;
  src: local(Montserrat-Medium), url("../font/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat-Black;
  src: local(Montserrat-Black), url("../font/Montserrat/static/Montserrat-Black.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat-Light;
  src: local(Montserrat-Light), url("../font/Montserrat/static/Montserrat-Light.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Montserrat-SemiBold;
  src: local(Montserrat-SemiBold), url("../font/Montserrat/static/Montserrat-SemiBold.ttf") format("truetype");
  font-style: normal;
  font-display: swap;
}

$Montserrat-ExtraBold:Montserrat-ExtraBold;
$Montserrat-Medium:Montserrat-Medium;
$Montserrat-Black:Montserrat-Black;
$Montserrat-Light:Montserrat-Light;
$Montserrat-SemiBold:Montserrat-SemiBold;

//Breakpoints:
$large-screen:1024px;
$small-screen:1023px;

//Colors :
$light-green:#C6F806;
$heading-color:#211962;
$purple-gradient:linear-gradient(314deg, #F2C1F3 0%, #ADA5F4 100%);
$green-gradient:linear-gradient(314deg, #A8E394 0%, #80EAE3 100%);
$orange-gradient:linear-gradient(314deg, #FFECA9 0%, #FEA98E 100%);
$blue: #240CF2;
$campaignsHeading:#101D46;