.tandc-page {
  padding: 20px;
  color: white;
  a {
    color: white;
  }
  .title {
    text-align: center;
  }
}
