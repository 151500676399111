@import "../../../../styles/global";

@media screen and (min-width:$large-screen) {
  .heroinputField {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    padding: 2vh 0 2vh 0;
    border-radius: 50px;
    width: 40%;

    .heroinput-left {
      display: flex;
      width: 80%;

      input {
        outline: none;
        border: none;
        font-family: $Montserrat-Black;
        font-style: italic;
        width: 100%;
        font-size: 2.5vw;
        border-radius: 25px;



        &::placeholder {
          font-family: $Montserrat-Black;
          font-style: italic;
          font-size: 2.5vw;
          position: relative;
          left: 1vw;
        }
      }
      input:-webkit-autofill, input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset;
      }
    }

    .heroinput-right {

      button {
        border-radius: 50%;
        background-color: $light-green;
        border-style: none;
        padding: .5vw;
        cursor: pointer;

        img {
          width: 3.5vw;
        }
      }
    }
  }

  .footerinputField {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    padding: .5vh .1vw .5vh 0;
    border-radius: 35px;
    //width: 40%;

    .footerinput-left {
      display: flex;
      width: 80%;

      input {
        outline: none;
        border: none;
        font-family: $Montserrat-Black;
        font-style: italic;
        width: 100%;
        font-size: 1.2vw;
        border-radius: 25px;



        &::placeholder {
          font-family: $Montserrat-Black;
          font-style: italic;
          font-size: 1.2vw;
          position: relative;
          left: 1vw;
        }
      }
      input:-webkit-autofill, input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset;
      }
    }

    .footerinput-right {

      button {
        border-radius: 50%;
        background-color: $light-green;
        border-style: none;
        padding: .5vw;
        cursor: pointer;

        img {
          width: 2.5vw;
        }
      }
    }
  }

}



@media screen and (max-width:$small-screen) {
  .heroinputField {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    padding: 1vw;
    border-radius: 35px;
    column-gap: 2vw;

    .heroinput-left {
      display: flex;
      width: 80%;

      input {
        outline: none;
        border: none;
        font-family: $Montserrat-Black;
        font-style: italic;
        width: 100%;
        font-size: 4vw;
        border-radius: 25px;



        &::placeholder {
          font-family: $Montserrat-Black;
          font-style: italic;
          font-size: 4vw;
          position: relative;
          left: 4vw;
        }
      }
      input:-webkit-autofill, input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset;
      }
    }

    .heroinput-right {

      button {
        border-radius: 50%;
        background-color: $light-green;
        border-style: none;
        padding: 2vw;
        cursor: pointer;

        img {
          width: 9vw;
        }
      }
    }
  }


  .footerinputField {
    display: flex;
    justify-content: space-around;
    background-color: #ffffff;
    padding: 1vw;
    border-radius: 35px;
    column-gap: 2vw;
    width: 80%;
    align-self: center;

    .footerinput-left {
      display: flex;
      width: 80%;

      input {
        outline: none;
        border: none;
        font-family: $Montserrat-Black;
        font-style: italic;
        width: 100%;
        font-size: 4vw;
        border-radius: 25px;



        &::placeholder {
          font-family: $Montserrat-Black;
          font-style: italic;
          font-size: 4vw;
          position: relative;
          left: 4vw;
        }
      }
      input:-webkit-autofill, input:-webkit-autofill:focus {
        box-shadow: 0 0 0 1000px white inset;
      }
    }

    .footerinput-right {

      button {
        border-radius: 50%;
        background-color: $light-green;
        border-style: none;
        padding: 2vw;
        cursor: pointer;

        img {
          width: 9vw;
        }
      }
    }
  }

}
