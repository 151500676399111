@import "../../styles/global";


.gameLibraryCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #EAEAEA;
  border-radius: 20px;
  max-width: 15.6vw;
  position: relative;
  overflow: hidden;

  .gameLibraryCardFront {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2vh;

    .gameLibraryCardLogo {
      img {
      }
    }

    .gameLibraryCardHeading {
      width: 90%;

      h1 {
        font-family: $Montserrat-ExtraBold;
        font-size: 1.3vw;
        margin-bottom: 0;
      }
    }

    .gameLibraryCardLabel {
      width: 90%;
      display: flex;
      gap: .5vw;
      //overflow: scroll;
      //white-space: nowrap;
      flex-wrap: wrap;

      .cardLabel {
        background-color: #E0E5FF;
        border-radius: 5px;
        cursor: pointer;

        p {
          font-family: $Montserrat-Medium;
          font-size: 0.8vw;
          color: #4764F6;
          padding: .2vh .3vw;
          margin: 0;
        }
      }
    }

    .brandDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;

      .brandDetails-logo {

        img {
          max-width: 1.5vw;
        }
      }

      .brandDetails-count {
        p {
          font-family: $Montserrat-Medium;
          font-size: .9vw;
        }
      }
    }
  }

  .gameLibraryCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    top: 100%;
    left: 0%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    text-align: center;
    gap: 2vh 0;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    h1 {
      font-family: $Montserrat-ExtraBold;
      font-size: 1.3vw;
    }

    button {
      border: 2px solid $blue;
      outline: none;
      width: 90%;
      align-self: center;
      font-family: $Montserrat-Medium;
      font-size: 1vw;
      padding: 1.5vh 0;
      border-radius: 25px;
      background-color: #ffffff;
      color: $blue;
      cursor: pointer;

      &:hover {
        border: 2px solid transparent;
        background-color: $blue;
        color: #ffffff;
      }
    }
  }

  &:hover {
.gameLibraryCardFront{
  filter: brightness(50%);
  transition: 0.5s;
}


    .gameLibraryCardBack {
      top: 30%;
      z-index: 2;

    }
  }
}

@media screen and (max-width: $small-screen){
  .gameLibraryCard {
    max-width: 100%;
    .gameLibraryCardFront {
      .gameLibraryCardLogo {
        img {
        }
      }

      .gameLibraryCardHeading {
        h1 {
          font-size: 4vw;
          margin: 0;
        }
      }

      .gameLibraryCardLabel {
        .cardLabel {
          p {
            font-size: 2.5vw;
            padding: 0.2vh 0.4vw;
          }
        }
      }

      .brandDetails {
        .brandDetails-logo {
          img {
            max-width: 5vw;
          }
        }

        .brandDetails-count {
          p {
font-size: 2.5vw;
          }
        }
      }
    }

    .gameLibraryCardBack {
      h1 {
font-size: 4vw;
      }

      button {
font-size: 3vw;
      }
    }
  }
}
