@import "../../../../styles/global";

@media screen and (min-width:$large-screen) {
  .navbar {
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin-top: 5vh;

    .navLeft {
      max-width: 20vw;

      img {
        width: 20vw;
      }
    }

    .navRight {
      display: flex;
      border-radius: 45px;
      box-shadow: 0 20px 500px 80px rgba(145, 56, 168, 0.75);
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-green;
        color: #000000;
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 2vw;
        width: 12vw;
        border-radius: 50px;
        padding: 0 7vw;
        outline-style: none;
        border-style: none;
        cursor: pointer;
      }
    }
  }
}


@media screen and (max-width:$small-screen) {
  .navbar {
    display: flex;
    width: 95%;
    justify-content: space-between;
    margin-top: 5vh;

    .navLeft {
      max-width: 30vw;

      img {
        width: 30vw;
      }
    }

    .navRight {
      display: flex;
      border-radius: 45px;
      box-shadow: 0 20px 500px 80px rgba(145, 56, 168, 0.75);
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-green;
        color: #000000;
        font-family: $Montserrat-Black;
        font-style: italic;
        font-size: 4vw;
        border-radius: 50px;
        padding: 2vh 8vw;
        outline-style: none;
        border-style: none;
        cursor: pointer;
      }
    }
  }

}

