@import "../../../../styles/global";


@media screen and (min-width: $large-screen) {
  .footer-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #0C063B;
    color: #ffffff;
    padding: 5vh 0;

    .footer-left {
      display: flex;
      position: relative;
      left: 2vw;

      .left-logo {
        display: flex;
        flex-direction: column;
        border-right: dotted;
        border-width: 2px;
        border-color: rgba(136, 136, 136, 0.88);
        justify-content: center;

        img {
          width: 60%;
        }

        h1 {
          font-family: $Montserrat-Light;
          font-size: 1.2vw;
          padding-right: 2vw;
        }
      }

      .left-input {
        display: flex;
        flex-direction: column;
        Position: relative;
        left: 3vw;

        h1 {
          font-family: $Montserrat-Light;
          font-size: 1.2vw;
          color: #F8F8F8;
        }
      }
    }


    .footer-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      position: relative;
      right: 2vw;
      row-gap: 2vh;

      .right-upper {
        display: flex;
        justify-content: space-evenly;
        width: 50%;

        img {

        }
      }

      .right-lower {
        display: flex;
        color: #6F6F6F;
        column-gap: .5vw;
        align-items: baseline;
        justify-content: center;

        span {
          font-family: $Montserrat-Light;
          font-size: 1vw;

          &:hover {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }

}


@media screen and (max-width: $small-screen) {

  .footer-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #0C063B;
    color: #ffffff;
    padding: 2vh 0;
    flex-direction: column;
    row-gap: 5vh;

    .footer-left {
      display: flex;
      flex-direction: column;
      width: 100%;
      //position: relative;
      //left: 2vw;

      .left-logo {
        display: flex;
        flex-direction: column;
        border-color: rgba(136, 136, 136, 0.88);
        justify-content: center;
        align-items: center;

        img {
          width: 40%;
        }

        h1 {
          font-family: $Montserrat-Light;
          font-size: 3vw;
          //padding-right: 2vw;
        }
      }

      .left-input {
        display: flex;
        flex-direction: column;
        Position: relative;
        //left: 3vw;

        h1 {
          font-family: $Montserrat-Light;
          font-size: 4vw;
          color: #F8F8F8;
          position: relative;
          width: 50%;
          left: 12vw;
        }
      }
    }


    .footer-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      row-gap: 2vh;

      .right-upper {
        display: flex;
        justify-content: space-evenly;
        width: 50%;

        img {

        }
      }

      .right-lower {
        display: flex;
        color: #6F6F6F;
        align-items: baseline;
        column-gap: 1vw;

        span {
          font-family: $Montserrat-Light;
          font-size: 3vw;

          &:hover {
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }

}