@import "../../../../styles/global";

@media screen and (min-width: $large-screen) {
  .ladderToUnlock-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 10vh 0;

    .ladder-heading {
      font-family: $Montserrat-Black;
      font-size: 3vw;
      font-style: italic;
      color: $heading-color;
    }

    .ladder-content {
      display: flex;
      justify-content: space-around;
      width: 100%;

    }


  }
}

@media screen and (max-width: $small-screen) {
  .ladderToUnlock-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 5vh 0;

    .ladder-heading {
      width: 100%;
      display: flex;
      justify-content: center;
      h1{
        font-family: $Montserrat-Black;
        font-size: 5vw;
        font-style: italic;
        color: $heading-color;
      }

    }

    .ladder-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      row-gap: 2vh;

    }


  }
}
