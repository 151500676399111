@import "../../../../styles/global";

$blue: #2765F6;

.login-div {
  display: flex;
  flex-direction: column;
  width: 80%;
  text-align: start;

  h1 {
    font-family: $Montserrat-Black;
  }

  .field-section {
    display: flex;
    flex-direction: column;
    width: 100%;
    row-gap: 2vh;

    label {
      font-family: $Montserrat-Medium;

    }

    input {
      font-family: $Montserrat-Medium;
      width: 100%;
      font-size: 1.5vw;
      border-radius: 15px;
      border: 1px solid #E4E6EB;
      padding: .5vw;
      outline: none;

      &:focus {
        border: 1px solid $blue;
        outline: 1px solid $blue;
        box-shadow: 0 0 10px $blue;
      }
    }

    input:-webkit-autofill, input:-webkit-autofill:focus {
      box-shadow: 0 0 0 1000px white inset;
    }

    .field-bottom-section {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;

      .field-bottom-left {
        width: 50%;
        display: flex;
        align-items: baseline;

        input[type="checkbox"] {
          width: 15%;

          &:focus {
            border: none;
            outline: none;
            box-shadow: none;
          }
        }

        .remember-me {
          font-size: 1vw;
        }

      }

      .field-bottom-right {
        font-family: $Montserrat-Medium;
        font-size: 1vw;
        color: $blue;

      }
    }

    a {
      width: 100%;

      button {
        border-style: none;
        padding: .5vw;
        cursor: pointer;
        background-color: $blue;
        font-size: 1.5vw;
        font-family: $Montserrat-Medium;
        color: #ffffff;
        border-radius: 15px;
        width: 100%;
      }
    }

  }

  .o-login-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .5vw;

    div {
      height: 1px;
      width: 12vw;
      background-color: #E4E6EB;
    }

    p {
      font-family: $Montserrat-Medium;
      font-size: 1vw;
    }
  }

  .logo-section {
    display: flex;
    justify-content: center;
    column-gap: 1vw;
  }
}


@media screen and (max-width: $small-screen) {
  .login-div {

    h1 {
    }

    .field-section {

      label {

      }

      input {

        height: 5vh;
        font-size: 4vw;
        text-align: center;

      }


      .field-bottom-section {
        align-items: center;

        .field-bottom-left {

          input[type="checkbox"] {
            width: 12%;
          }

          .remember-me {
            font-size: 2.5vw;
            align-self: center;

          }

        }

        .field-bottom-right {
          p {
            font-size: 2.5vw;
            align-self: center;

          }
        }
      }

      a {
        button {
          height: 5vh;
          font-size: 5vw;

        }
      }

    }

    .o-login-section {
      column-gap: 2.5vw;

      div {
        width: 22vw;
      }

      p {
        font-size: 3vw;
      }
    }

    .logo-section {
      column-gap: 3vw;
    }
  }

}