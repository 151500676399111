@import "../../styles/global";


.campaignsCard {
  display: flex;
  width: 100%;
  justify-content: center;
  border: 1px solid #EAEAEA;
  border-radius: 15px;

  .campaignsCard-wrapper {
    //width: 95%;
    display: flex;
    column-gap: 1vw;
    padding: 1vw;


    .campaignsCard-img {
      max-width: 9vw;

      img {
        width: 9vw;
      }
    }

    .campaignsCard-content {
      display: flex;
      flex-direction: column;
      text-align: start;

      .content-h {
        display: flex;
        justify-content: space-between;

        svg {
          font-size: 1.5vw;
          display: none;
        }

      }

      h2 {
        font-family: $Montserrat-ExtraBold;
        font-size: 1.2vw;
        width: 15vw;
        margin: 0;
        color: $campaignsHeading;
      }

      p {
        font-family: $Montserrat-Medium;
        font-size: 1vw;
      }

      .campaignsCard-ph {
        color: #7E8088;
        margin-top: 0;
      }

      .campaignsCard-pd {
        color: $campaignsHeading;
      }
    }

    &:hover {
      .campaignsCard-content{
        .content-h{
          svg {
            display: block;
            transition: all 1s;
            cursor: pointer;
          }
        }
      }

    }
  }
}