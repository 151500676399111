@import "../../styles/global";

.side-menu {
  background-color: $blue;
  height: 100vh;
  height: 100svh;
  width: 80px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  transition: width 0.5s;
  overflow: hidden;

  &.expanded {
    width: 220px;
  }

  .head {
    padding: 30px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    .logo {
      width: 80%;
    }
    svg{
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    overflow: hidden;
    margin-bottom: 1vh;
  }

}
